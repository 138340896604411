<template>
  <div class="manage_activity wrapper">
    <proTitle></proTitle>
    <div class="activity-container">
      <el-form :inline="true" :model="formInline" class="demo-form-inline ">
        <div class="formInline-left">
          <el-form-item label="">
            <el-date-picker size="mini" v-model="formInline.datetimerange" type="datetimerange" start-placeholder="开始日期"
              range-separator="至" end-placeholder="结束日期" prefix-icon="el-icon-date" @change="filterCourse"
              value-format="yyyy-MM-dd HH:mm:ss" :editable="false" class="date-picker">
            </el-date-picker>
          </el-form-item>
          <el-form-item label="活动类型 :">
            <el-select v-model="formInline.activityType" placeholder="活动类型" @change="filterCourse()"
              style="width: 130px;">
              <el-option label="全部" value="">全部</el-option>
              <el-option label="大赛类" value="0"></el-option>
              <el-option label="教研类" value="1"></el-option>
              <el-option label="合作学习墙" value="2"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="活动状态 :">
            <el-select v-model="formInline.status" placeholder="活动状态" @change="filterCourse()" style="width: 100px;">
              <el-option label="全部" value="">全部</el-option>
              <el-option label="未开始" value="0"></el-option>
              <el-option label="进行中" value="1"></el-option>
              <el-option label="已结束" value="2"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label>
            <el-input @input="filterCourse()" v-model="formInline.search" size="medium" placeholder="请输入活动名称/发起人"
              suffix-icon="el-icon-search"></el-input>
          </el-form-item>
        </div>
        <div class="formInline-right flex-layout">
          <div class="form-inline-item csp" style="margin:0 0 10px;">
            <i class="el-icon-circle-plus-outline"></i>
            <span @click="$router.push({path:`/activity/add`,query:{breadNumber:4}})">创建活动</span>
          </div>
        </div>
      </el-form>
      <div class="table_section">
        <el-table :data="tableData" border style="width: 100%">
          <el-table-column prop="number" label="序号" width="80" align="center" type="index" :index="indexMethod">
          </el-table-column>
          <!-- <el-table-column prop="id" label="活动编号" width="100" align="center">
          </el-table-column> -->
          <el-table-column prop="name" label="活动名称" width="220" align="center">
          </el-table-column>
          <el-table-column label="活动时间" width="260" align="center">
            <template slot-scope="scope">
              <p> {{dayjs(scope.row.activityBeginTime).format('YYYY-MM-DD HH:mm')}} -
                {{dayjs(scope.row.activityEndTime).format('YYYY-MM-DD HH:mm')}}</p>
            </template>

          </el-table-column>
          <el-table-column prop="activityType" label="活动类型" width="170" align="center">
            <template slot-scope="scope">
              {{activityTypeMap[scope.row.activityType]}}
            </template>
          </el-table-column>
          <el-table-column prop="status" label="活动状态" width="170" align="center">
            <template slot-scope="scope">
              {{statusMap[scope.row.status]}}
            </template>
          </el-table-column>
          <el-table-column fixed="right" label="操作" align="center" width="180">
            <template slot-scope="scope">
              <el-button @click="handleClick(scope.row)" type="text" size="small">查看</el-button>
              <el-button type="text" size="small"
                @click="$router.push({path:`/activity/edit/${scope.row.id}`,query:{breadNumber:4}})"
                :disabled="scope.row.status==2">编辑
              </el-button>
              <el-button type="text" size="small" @click="deleteCourse(scope.row.id)" :disabled="scope.row.status==2">删除
              </el-button>
              <br>
              <!-- <el-button type="text" size="small" @click="$router.push(`/activity/signUp/${scope.row.id}`)">报名情况 -->
              <el-button type="text" size="small"
                @click="$router.push(`/activity/${scope.row.activityType == 0 ? 'stage':'child'}/detail/${scope.row.id}?breadNumber=4`)">
                子活动
              </el-button>
              <el-button type="text" size="small" @click="$router.push(`/activity/data/${scope.row.id}?breadNumber=4`)"
                v-if="scope.row.activityType!=0">
                活动数据
              </el-button>
              <el-button type="text" size="small" @click="getGoYzTotalCollectionUrl(scope.row)"
                v-if="scope.row.activityType == 2">
                作品集
              </el-button>
            </template>
          </el-table-column>
        </el-table>
        <pagination v-show="pages.total > 0" :total="pages.total" :page.sync="pages.currentPage"
          :limit.sync="pages.pageSize" @pagination="handlePageChange">
        </pagination>
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    inject: ['reload'],
    data() {
      return {
        tableData: [],
        formInline: {
          activityType: "",
          status: "",
          search: "",
          datetimerange: '',
        },
        pages: { //分页
          currentPage: 1,
          pageSize: 10,
          total: 0,
        },
        activityTypeMap: {
          0: "大赛类",
          1: "教研类",
          2: "合作学习墙",
        },
        statusMap: {
          0: "未开始",
          1: "进行中",
          2: "已结束",
        },
        yzCollectionUrl: '', //作品集地址
      };
    },
    computed: {},
    watch: {
      '$store.state.user.currentProject.id': {
        handler(newVal, oldVal) {
          if (!newVal) return;
          this.pages.currentPage = 1;
          this.getTotalActivities();
        }
      },
    },
    beforeRouteEnter(to, from, next) {
      // console.log(from);
      next(vm => {
        if (from.name == 'activityEdit' || (from.matched.length > 0 && from
            .matched[1].name == 'activityAdd')) {
          vm.getTotalActivities();
        }
      });
    },
    methods: {
      indexMethod(index) {
        return this.pages.currentPage > 1 ?
          (this.pages.pageSize * (this.pages.currentPage - 1)) + index + 1 :
          index + 1
      },
      handlePageChange(data) {
        this.pages.currentPage = data.pageIndex
        this.pages.pageSize = data.pageSize
        // console.log(this.pages);
        this.getTotalActivities();
      },
      // 查看点击
      handleClick(tab, event) {
        // console.log(tab, event);
        this.$router.push({
          path: `/activity/detail/${tab.id}`,
          query: {
            breadNumber: 4
          }
        });
      },
      toYzCollection() {
        if (!this.yzCollectionUrl) return;
        window.open(this.yzCollectionUrl);
      },
      async getGoYzTotalCollectionUrl(item) {
        let params = {
          activityId: item.id,
          type: 1 // 0 参与 1创建
        }
        let resData = await this.$Api.Activity.getGoYzTotalCollectionUrl(params);
        console.log(resData);
        this.yzCollectionUrl = resData.data + `&title=${item.name}&role=1`
        this.toYzCollection();
      },
      // 筛选课程 [包括：筛选课程类型，筛选课程时间，模糊查询课程] 
      filterCourse() {
        this.pages.currentPage = 1;
        this.getTotalActivities();
      },
      // 删除课程
      deleteCourse(id) {
        // console.log(id);
        this.$confirm('是否删除该活动?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          // let resData = this.$Api.Course.commentDel(item.id)
          // this.$Api.Course.activityDel(id).then((resData) => {
          this.$Api.Activity.delActivity(id).then((resData) => {
            // console.log(resData)
            if (resData.code === 200 && resData.data == 1) {
              this.$message({
                message: '删除成功',
                type: 'success'
              });
              this.getTotalActivities();
            } else {
              this.$message.error(resData.msg);
            }
          })
        }).catch(() => {
          this.$message({
            type: 'info',
            message: '已取消删除'
          });
        });
      },
      // 获取活动数据列表
      async getTotalActivities() {
        let params = {
          page: this.pages.currentPage,
          limit: this.pages.pageSize,
          projectId: this.$store.state.user.currentProject.id,
        }
        this.formInline.activityType ? params.activityType = this.formInline.activityType : '';
        this.formInline.status ? params.status = this.formInline.status : '';
        this.formInline.search ? params.search = this.formInline.search : '';
        this.formInline.datetimerange ? params.activityBeginTime = this.formInline.datetimerange[0] : '';
        this.formInline.datetimerange ? params.activityEndTime = this.formInline.datetimerange[1] : '';
        // let resData = await this.$Api.Course.getManageActivity(params);
        let resData = await this.$Api.Project.getTotalActivities(params);
        // console.log(resData.data)
        this.tableData = resData.data.list;
        this.pages.total = resData.data.totalCount
      },
    },
    mounted() {
      this.getTotalActivities();
    },
  };
</script>

<style lang="less" scoped>
  @import "../manage/less/table.less";

  .el-form--inline .formInline-right {
    justify-content: flex-end;
  }

  .manage_activity {
    padding: 20px 25px;
    box-sizing: border-box;
  }
</style>